import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import ZoomInfoHeaderWrapper, { PlanBox4 } from './zoominfoHeader.style';
import { PlanBox3, PlanBox2, FeatPlan } from './zoominfoHeader.style';
import xross from '../../../../common/assets/image/pricing/cross_circle.svg';
import check from '../../../../common/assets/image/pricing/tick_circle.svg';
import Focus from 'common/assets/image/saasModern/CRM/Focus.svg';
import Matching from 'common/assets/image/saasModern/CRM/Matching.svg';
import Outcome from 'common/assets/image/saasModern/CRM/Outcome.svg';
import Benifits from 'common/assets/image/saasModern/CRM/Benefit.svg';
import Risk from 'common/assets/image/saasModern/CRM/Risk.svg';
import BlueCheck from 'common/assets/image/saasModern/CRM/BlueCheck.svg';
import GryCheck from 'common/assets/image/saasModern/CRM/GryCheck.svg';

import { Link } from 'gatsby';

const CompareCard = () => {
  const [progress, setProgress] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);

  const sections = [
    { image: Focus, title: 'Focus' },
    { image: Matching, title: 'Matching Techniques' },
    { image: Outcome, title: 'Outcome' },
    { image: Benifits, title: 'Benefits' },
    { image: Risk, title: 'Potential Risk' },
  ];

  const PlanBox2Data = [
    {
      title: 'Deduplication',
      icon: BlueCheck,
    },
    {
      title: 'Enrichment',
      icon: BlueCheck,
    },
    {
      title: 'Updating Data',
      icon: BlueCheck,
    },
    {
      title: 'Social Profiles',
      icon: BlueCheck,
    },
    {
      title: 'Advanced Algorithms',
      icon: BlueCheck,
    },
    {
      title: 'Clean Data',
      icon: BlueCheck,
    },
    {
      title: 'Enriched Data',
      icon: BlueCheck,
    },
    {
      title: 'Single Customer View',
      icon: BlueCheck,
    },
    {
      title: 'Improved Data Accuracy',
      icon: BlueCheck,
    },
    {
      title: 'Increased efficiency',
      icon: BlueCheck,
    },
    {
      title: 'Better Targeting',
      icon: BlueCheck,
    },
    {
      title: 'Deeper Insights',
      icon: BlueCheck,
    },
    {
      title: 'None',
      icon: BlueCheck,
    },
  ];

  const PlanBox3Data = [
    {
      title: 'Eliminating Duplicates',
      icon: GryCheck,
    },
    {
      title: 'Basic Field Matching',
      icon: GryCheck,
    },
    {
      title: 'Deduplicated Data',
      icon: GryCheck,
    },
    {
      title: 'Improved Data Accuracy',
      icon: GryCheck,
    },
    {
      title:
        'Loss of accurate data if merging is incorrect, inaccurate identification of duplicates',
      icon: GryCheck,
    },
  ];

  const PlanBox4Data = [
    {
      title: 'Fixing data errors',
      icon: GryCheck,
    },
    {
      title: 'Fuzzy matching, data validation rules',
      icon: GryCheck,
    },
    {
      title: 'Error-Free Data',
      icon: GryCheck,
    },
    {
      title: 'Consistent Data Format',
      icon: GryCheck,
    },
    {
      title:
        'Deleting information accidentally, overlooking valid variations in data',
      icon: GryCheck,
    },
  ];

  useEffect(() => {
    const sectionDuration = 20; // Duration for each section in milliseconds
    const totalDuration = sections.length * sectionDuration;
    const intervalDuration = 120; // Interval duration in milliseconds

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          setCurrentSection(
            (prevSection) => (prevSection + 1) % sections.length
          );
          return 0;
        }

        // Calculate the duration of the current section
        const currentIndex = Math.floor(prevProgress / (100 / sections.length));
        const currentSectionDuration =
          currentIndex === sections.length - 1
            ? 100 - currentIndex * (100 / sections.length)
            : sectionDuration;

        // Calculate the increment based on the duration of the current section
        const increment =
          (intervalDuration / currentSectionDuration) * (100 / totalDuration);

        return prevProgress + increment;
      });
    }, intervalDuration);

    return () => {
      clearInterval(interval);
    };
  }, [sections.length]);

  const baseOpacity = 0.5;

  const calculateOpacity = (sectionIndex, currentIndex, currentProgress) => {
    const baseOpacity = 0.5;
    return sectionIndex < currentIndex
      ? 1
      : sectionIndex === currentIndex
      ? 1 // Set opacity to 1 when the current section is active
      : baseOpacity;
  };
  return (
    <>
      <ZoomInfoHeaderWrapper>
        <div className="headingOne">
          <h2 className="h2">
            Clodura.AI CRM Cleanup API:
            <br />
            <span className="Heading-text">
              Beyond Deduplication and Cleansing
            </span>
          </h2>
        </div>
      </ZoomInfoHeaderWrapper>
      <CompareCardWrapper>
        <ZoomInfoHeaderWrapper>
          <FeatPlan>
            {sections.map((section, index) => (
              <div
                key={index}
                className="feature-list feature-section feat-box"
                style={{
                  opacity:
                    index < currentSection
                      ? 1
                      : index === currentSection
                      ? 1 // Set opacity to 1 when the current section is active
                      : baseOpacity,
                }}
              >
                <div className="left-content">
                  <img
                    className="feat-img"
                    src={section.image}
                    alt="Xross Image"
                  />
                  <h4>{section.title}</h4>
                </div>
                <div className="right-content">
                  <div className="progress-slider">
                    <div
                      className="progress-bar"
                      style={{
                        height: `${
                          index === currentSection
                            ? progress
                            : index < currentSection
                            ? 100
                            : 0
                        }%`,
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </FeatPlan>

          <PlanBox2>
            <div className="plan-info">
              <h3>
                Clodura.AI CRM
                <br />
                Cleanup API
              </h3>
            </div>
            <div className="feature-list feature-section">
              <div className="gap first-section">
                {PlanBox2Data.slice(0, 3).map((item, index) => (
                  <p
                    key={index}
                    style={{
                      opacity: calculateOpacity(0, currentSection, progress),
                    }}
                  >
                    <img
                      className="xross-tick"
                      src={item.icon}
                      alt="Xross Image"
                    />
                    {item.title}
                  </p>
                ))}
              </div>
              <div className="gap second-section">
                {PlanBox2Data.slice(3, 5).map((item, index) => (
                  <p
                    key={index}
                    style={{
                      opacity: calculateOpacity(1, currentSection, progress),
                    }}
                  >
                    <img
                      className="xross-tick"
                      src={item.icon}
                      alt="Xross Image"
                    />
                    {item.title}
                  </p>
                ))}
              </div>
              <div className="gap third-section">
                {PlanBox2Data.slice(5, 8).map((item, index) => (
                  <p
                    key={index}
                    style={{
                      opacity: calculateOpacity(2, currentSection, progress),
                    }}
                  >
                    <img
                      className="xross-tick"
                      src={item.icon}
                      alt="Xross Image"
                    />
                    {item.title}
                  </p>
                ))}
              </div>
              <div className="gap fourth-section">
                {PlanBox2Data.slice(8, 12).map((item, index) => (
                  <p
                    key={index}
                    style={{
                      opacity: calculateOpacity(3, currentSection, progress),
                    }}
                  >
                    <img
                      className="xross-tick"
                      src={item.icon}
                      alt="Xross Image"
                    />
                    {item.title}
                  </p>
                ))}
              </div>
              <div>
                {PlanBox2Data.slice(12).map((item, index) => (
                  <p
                    key={index}
                    style={{
                      marginTop: '40px',
                      marginBottom: '50px',
                      opacity: calculateOpacity(4, currentSection, progress),
                    }}
                  >
                    <img
                      className="xross-tick"
                      src={item.icon}
                      alt="Xross Image"
                    />
                    {item.title}
                  </p>
                ))}
              </div>
            </div>
          </PlanBox2>

          <PlanBox3>
            <div className="plan-info">
              <h3>
                CRM Data <br /> Deduplication
              </h3>
            </div>
            <div className="feature-list feature-section">
              {PlanBox3Data.map((item, index) => (
                <div className="gap2" key={index}>
                  {index === 0 || index === 2 ? <p></p> : null}
                  <p
                    style={{
                      opacity: calculateOpacity(
                        index,
                        currentSection,
                        progress
                      ),
                    }}
                  >
                    <img
                      className="xross-tick"
                      src={item.icon}
                      alt="Xross Image"
                    />
                    {item.title}
                  </p>
                  {index === 0 || index === 2 ? <p></p> : null}
                </div>
              ))}
            </div>
          </PlanBox3>

          <PlanBox4>
            <div className="plan-info">
              <h3>
                CRM Data <br /> Cleansing
              </h3>
            </div>
            <div className="feature-list feature-section">
              {PlanBox4Data.map((item, index) => (
                <div className="gap2" key={index}>
                  {index === 0 || index === 2 ? <p></p> : null}
                  <p
                    style={{
                      opacity: calculateOpacity(
                        index,
                        currentSection,
                        progress
                      ),
                    }}
                  >
                    <img
                      className="xross-tick"
                      src={item.icon}
                      alt="Xross Image"
                    />
                    {item.title}
                  </p>
                  {index === 0 || index === 2 ? <p></p> : null}
                </div>
              ))}
            </div>
          </PlanBox4>
        </ZoomInfoHeaderWrapper>
      </CompareCardWrapper>
    </>
  );
};

export default CompareCard;

const CompareCardWrapper = styled.div`
  overflow-x: auto;
  padding: 20px;
`;
