import styled from 'styled-components';

const ZoomInfoHeaderWrapper = styled.section`
  display: flex;
  margin: 0px auto;
  align-items: center;
  justify-content: center;
  /* background: url(${(props) => (props.avatar ? props.avatar : null)}); */
  background-position: center center;
  background-repeat: no-repeat;
  color: black;
  padding: 20px;
  /* margin-bottom: 10px; */
  gap: 20px;
  /* .feature-section {
    display: flex;
    flex-direction: column;
    justify-content: left;

    align-items: center;
    height: 100%;
  } */

  .headingOne {
    width: 900px;
    padding: 0px;
    background-color: #03baed;
    color: white;
    border-radius: 20px;

    text-align: center;
    .h2 {
      font-size: 40px;
    }

    .Heading-text {
      font-weight: 500; /* Adjust the font weight as needed */
    }

    @media screen and (max-width: 768px) {
      margin: 0;
      font-size: 12px;
      width: 100%;
      height: 280px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 100%;
    }
  }
`;

export const FeatPlan = styled.div`
  width: 20%;
  padding: 20px;
  border-radius: 10px;
  /* align-content: flex-end; */
  justify-content: right;
  /* border: 2px solid lightgray; */
  align-items: end;

  .feat-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
    @media screen and (max-width: 425px) {
      width: 100%;
    }

    @media screen and (max-width: 430px) {
      width: 100%;
    }
  }

  .left-content {
    display: flex;
    flex-direction: column;
    align-items: right;
  }

  .right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 20px;  */
  }

  .progress-slider {
    position: relative;
    height: 80px; /* Adjust the height as needed for the length of the column */
    background-color: #eee;
  }

  .progress-bar {
    width: 5px; /* Adjust the width as needed for the thickness */
    background-color: #03baed;
    transition: height 0.4s;
  }

  /* Add media queries for responsiveness if necessary */

  @media screen and (max-width: 600px) {
    width: 50%;
    margin-left: 10px;
  }
  @media screen and (max-width: 420px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  @media screen and (max-width: 435px) {
    h4 {
      font-size: 16px;
    }
  }

  .feat-box {
    margin: 20px;
  }
  .feat-img {
    width: 40px;
    /* align-items: end; */
    margin-bottom: -20px;
  }

  h4 {
    font-size: 26px;
    color: #03baed;
    margin-bottom: 10px;
    @media screen and (max-width: 1200px) {
      font-size: 13px;
    }

    @media screen and (max-width: 576px) {
      font-size: 10px;
    }

    @media screen and (max-width: 425px) {
      font-size: 8px;
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export const PlanBox2 = styled.div`
  width: 24%;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 150px;

  /* border: 2px solid lightgray; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 435px) {
    padding: 4px;
  }
  .xross-tick {
    height: 22px;
    margin-bottom: 2px;
    margin-right: 4px;
    @media screen and (max-width: 435px) {
      height: 12px;
    }
  }

  .feat-img {
    height: 25px;
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 170px;
    /* margin-bottom: 90px; */

    > h3 {
      text-align: center;
      font-size: 32px;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        height: 55px;
      }
      @media screen and (max-width: 435px) {
        font-size: 12px;
      }
    }
  }

  h2,
  h3 {
    margin: 0;
    margin-bottom: 20px;
    @media screen and (max-width: 435px) {
      font-size: 12px;
    }
  }

  .gap {
    min-height: 100px;
  }

  .first-section {
    margin-top: -30px;
  }
  .second-section {
    margin-top: 50px;
    @media screen and (max-width: 435px) {
      margin-top: 0px;
    }
  }
  .fourth-section {
    margin-top: 40px;
  }
  p {
    font-size: 16px;
    margin: 0;
    margin-top: 5px;
    /* height: 30px; */
    display: flex;
    justify-content: left;
    align-items: center;
    /* border-bottom: 1px solid black; */
    @media screen and (max-width: 435px) {
      font-size: 10px;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export const PlanBox3 = styled.div`
  width: 20%;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid black;
  gap: 40px;
  margin-bottom: 60px;

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 435px) {
    padding: 4px;
  }

  .feat-img {
    height: 25px;
  }

  .xross-tick {
    height: 15px;
    margin-right: 4px;
  }

  .strikethrough {
    display: block;
    color: darkgray;
    text-decoration: line-through;
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* height: 150px; */

    > h3 {
      text-align: center;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        height: 55px;
      }
      @media screen and (max-width: 435px) {
        font-size: 12px;
      }
    }
  }

  h4 {
    font-size: 34px;
    @media screen and (max-width: 1200px) {
      font-size: 27px;
    }

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }

    @media screen and (max-width: 435px) {
      font-size: 10px;
    }
  }

  h2,
  h3 {
    margin-bottom: 40px;
  }

  p {
    font-size: 12px;
    margin: 0;
    /* height: 10px; */
    display: flex;
    justify-content: left;
    align-items: center;
    /* border-bottom: 1px solid black; */
    @media screen and (max-width: 435px) {
      font-size: 10px;
    }
  }

  .gap {
    min-height: 110px;
  }
  .gap2 {
    min-height: 110px;
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
    }
  }

  .demo-btn {
    background-color: #f58634;
    :hover {
      background-color: #86bc4c;
      color: black;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export const PlanBox4 = styled.div`
  width: 20%;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid black;
  gap: 40px;
  margin-bottom: 60px;

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 435px) {
    padding: 4px;
  }

  .feat-img {
    height: 25px;
  }

  .xross-tick {
    height: 15px;
    margin-right: 4px;
  }

  .strikethrough {
    display: block;
    color: darkgray;
    text-decoration: line-through;
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* height: 150px; */

    > h3 {
      text-align: center;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        height: 55px;
      }
      @media screen and (max-width: 435px) {
        font-size: 12px;
      }
    }
  }

  h4 {
    font-size: 34px;
    @media screen and (max-width: 1200px) {
      font-size: 27px;
    }

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }

    @media screen and (max-width: 435px) {
      font-size: 10px;
    }
  }

  h2,
  h3 {
    margin-bottom: 40px;
  }

  p {
    font-size: 12px;
    margin: 0;
    /* height: 10px; */
    display: flex;
    justify-content: left;
    align-items: center;
    /* border-bottom: 1px solid black; */
    @media screen and (max-width: 435px) {
      font-size: 10px;
    }
  }

  .gap {
    min-height: 110px;
  }
  .gap2 {
    min-height: 110px;
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
    }
  }

  .demo-btn {
    background-color: #f58634;
    :hover {
      background-color: #86bc4c;
      color: black;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export default ZoomInfoHeaderWrapper;
