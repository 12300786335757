import React, { useState } from 'react';
import { Link } from 'gatsby';
import ZoomInfoHeaderWrapper from './zoominfoHeader.style';
import BookDemoPopup from '../BookDemoPopUp'; // Import your pop-up component
import LeftImg from 'common/assets/image/saasModern/WebApis/Left.png';
import RightImg from 'common/assets/image/saasModern/WebApis/Right.png';

const Footer = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleBookDemoClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <ZoomInfoHeaderWrapper>
      <div className="bottom-div">
        <h2>Unleash the Power of Clean Data</h2>
        <div className="main-div">
          <img src={LeftImg} alt="Left Image" />
          <p>
            Transform your CRM into a pristine source of truth and drive
            business success. Integrate CRM Cleanup API today.
          </p>
          <img src={RightImg} alt="Right Image" />
        </div>
        <button className="btn" onClick={handleBookDemoClick}>
          Book A Demo
        </button>
        {showPopup && <BookDemoPopup onClose={handlePopupClose} />}
      </div>
    </ZoomInfoHeaderWrapper>
  );
};

export default Footer;
