import styled from 'styled-components';

const ContainerSectionWrapper = styled.div`
  display: flex;
  padding: 0px 40px;
  /* padding-top: 50px; */
  justify-content: space-around;

  .heading-container {
    display: flex;
    align-items: center;
    position: relative;
    @media screen and (max-width: 768px) {
      font-size: 22px;
      margin-top: 7rem;
    }
  }

  .heading-tag {
    text-align: center;
    font-size: 36px;
    margin-top: 130px;
    position: relative;
    z-index: 1;
  }

  .small-span {
    font-size: 52px; /* Default font size for spans */
  }

  @media screen and (max-width: 425px) {
    .small-span {
      font-size: 35px; /* Adjust font size for spans on small screens */
    }
  }

  .triangle-right {
    position: relative;
    z-index: -1;
    /* margin-right: -20px; */
  }
  .triangle-left {
    position: relative;
    z-index: -1;
    /* margin-left: -20px; */
  }

  .fm {
    max-width: 700px;
    padding: 40px 0px;
    margin-top: 120px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    background-color: #fdfdfd;

    @media screen and (max-width: 768px) {
      max-width: 430px;
    }

    label {
      display: block;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 22px;
    }

    input[type='text'],
    input[type='email'] {
      width: 350px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      /* margin-bottom: 15px; */
      font-size: 16px;
    }

    span.invalid-email {
      color: red;
      display: block;
      margin-top: 5px;
      font-size: 12px;
    }

    button[type='submit'] {
      background-color: #f58634ff;
      color: white;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      width: 50%;
      margin: 0 auto; /* Center the button horizontally */
      display: block; /* Make the button take up full width */
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #86bc4c;
      }

      @media screen and (max-width: 768px) {
        padding: 0%;
      }
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 7rem;
      input[type='text'],
      input[type='email'] {
        width: 300px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        /* margin-bottom: 15px; */
        font-size: 16px;
      }
    }
  }

  .calendly-inline-widget {
    width: 80%;
    /* height: 50%;  */
    margin: 0 auto; /* Center the widget horizontally */
    padding: 10px;
    overflow: hidden;
    /* Adjust width for smaller screens */
    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }

  .link-container {
    @media screen and (max-width: 768px) {
      margin-bottom: 7rem;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0px auto;
    .calendly-inline-widget {
      width: 50%;
    }
  }
`;
export const InputGroup = styled.div`
  padding: 60px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  text-align: left;

  @media screen and (max-width: 425px) {
    padding: 15px;
  }

  label {
    font-weight: 700;
    font-size: 16px;

    @media screen and (max-width: 425px) {
      font-size: 14px;
    }
  }
`;

export default ContainerSectionWrapper;
