import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from 'common/components/Button';
import hr from 'common/assets/image/saasModern/WebApis/Vector 30.svg';
import ZoomInfoHeaderWrapper from './zoominfoHeader.style';
import { Link } from 'gatsby';
import OkIcon from 'common/assets/image/saasModern/WebApis/ok_icon.svg';
import Footer from '../Footer';

const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiCrmCleanupApi {
        nodes {
          img1 {
            localFile {
              publicURL
            }
          }
          img2 {
            localFile {
              publicURL
            }
          }
          img3 {
            localFile {
              publicURL
            }
          }
          img4 {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img = ZoominfoPageData.allStrapiCrmCleanupApi.nodes[0];

  const listStyle = {
    listStylePosition: 'inside', // This style moves the bullets inside the list items
  };

  return (
    <>
      <ZoomInfoHeaderWrapper>
        <img
          className="right-img first-img"
          src={`${img.img1.localFile.publicURL}`}
        />
        <div className="text">
          <div>
            <div className="headingOne">
              <h2 className="h2">
                Unleash the Power of <br /> Data Integrity
              </h2>
            </div>
          </div>
          <div className="paragraph">
            <p>
              With CRM Cleanup API, you can seamlessly cleanse and enrich your
              CRM data, ensuring you have the most accurate and up-to-date
              information at your fingertips. Simply provide us with a contact's
              first name, last name, and company name, and our API will provide
              you with a comprehensive profile, including:
            </p>

            <p>
              <img src={OkIcon} /> <b>Full Contact Information:</b> Access
              verified email addresses, phone numbers, and social media profiles
              to maintain seamless communication channels
            </p>
            <p>
              <img src={OkIcon} /> <b>Job Title and Company:</b> Gain valuable
              insights into your contacts' professional backgrounds and
              affiliations for enhanced targeting and personalization.
            </p>
            <p>
              <img src={OkIcon} /> <b>Data Validation and Correction:</b>{' '}
              Identify and rectify inconsistencies and inaccuracies, ensuring
              your CRM data is reliable and trustworthy.
            </p>

            <p>
              This enriched data empowers you to make informed decisions about
              your marketing efforts, sales strategies, and customer engagement.{' '}
            </p>
          </div>
        </div>
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <div className="text">
          <h2>Experience the Benefits of Clean Data</h2>
          <div className="para">
            <p>
              <img src={OkIcon} /> <b>Enhanced Customer Segmentation:</b> Create
              highly targeted marketing campaigns based on accurate and
              up-to-date customer data, maximizing ROI.
            </p>
            <p>
              <img src={OkIcon} /> <b>Improved Lead Qualification:</b>{' '}
              Accurately assess the potential of each lead, focusing your sales
              efforts on the most promising prospects.
            </p>
            <p>
              <img src={OkIcon} /> <b>Personalized Customer Experiences:</b>{' '}
              Deliver personalized interactions that resonate with each
              customer, fostering loyalty and satisfaction.
            </p>
          </div>
        </div>
        <img
          className="right-img second-img"
          src={`${img.img2.localFile.publicURL}`}
        />
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <img className="right-img" src={`${img.img3.localFile.publicURL}`} />
        <div className="text">
          <h2>Embrace Data-Driven CRM Excellence </h2>
          <div className="para">
            <p>
              CRM Cleanup API is your gateway to data-driven CRM excellence.
              With our API, you can:
            </p>

            <p>
              <img src={OkIcon} /> <b>Automate Data Enrichment:</b> Streamline
              data cleansing and enrichment processes, saving time and
              eliminating errors.
            </p>
            <p>
              <img src={OkIcon} /> <b>Maintain Data Integrity:</b> Proactively
              identify and rectify data inconsistencies, ensuring your CRM
              remains a reliable source of truth.
            </p>
            <p>
              <img src={OkIcon} /> <b>Automate Data Enrichment:</b> Streamline
              data cleansing and enrichment processes, saving time and
              eliminating errors.
            </p>
          </div>
        </div>
      </ZoomInfoHeaderWrapper>
      <Footer />
    </>
  );
};

export default ZoomInfoHeader;
