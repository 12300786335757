import styled from 'styled-components';

const ZoomInfoHeaderWrapper = styled.section`
  display: flex;
  margin: 30px 40px;
  align-items: center;
  justify-content: space-around;
  /* background: url(${(props) => (props.avatar ? props.avatar : null)}); */
  background-position: center center;
  background-repeat: no-repeat;
  color: black;
  padding: 0px 20px;

  .right-img {
    width: 45%;
    @media screen and (max-width: 420px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  .bottom-div {
    background: #e3f9ff;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* padding: 0 20%; */
    text-align: center;
    border: 2px solid black;
    margin-top: 40px;
    height: 232px;
    width: 90%;
    > h2 {
      font-size: 30px;
      color: white;
      background: black;
      padding: 10px 20px;
      margin-top: -35px;
      border-radius: 5px;
    }
    p {
      margin-top: -4px;
      width: 100%;
      font-size: 18px;
      text-align: center;
    }

    .main-div {
      display: flex;
      width: 100%;
      > Img {
        margin-top: -60px;
        width: 130px;

        @media screen and (max-width: 440px) {
          display: none;
        }
      }
    }

    button {
      color: white;
      background-color: #f58634ff;
      border: none;
      font-weight: 700;
      font-size: 18px;
      border-radius: 5px;
      width: 16rem;
      height: 56px;
      margin-top: -150px;
      position: relative;
      z-index: 999;
      top: -130%;
      cursor: pointer;
      :hover {
        background-color: #86bc4c;
        color: black;
        font-weight: 600;
        transition: all 0.3s ease 0s;
      }

      @media screen and (max-width: 440px) {
        font-size: 12px;
        width: 100%;
        height: 35px;
      }
    }

    @media screen and (max-width: 440px) {
      font-size: 12px;
      width: 100%;
      /* height: 35px; */
      padding: 5px;
      height: 500px;
      .btn {
        top: 10px;
      }
    }
  }
  .text {
    width: 45%;
    /* margin-right: 20px; */
    /* margin-left: 90px; */
    text-align: left;

    h2 {
      font-size: 32px;
      margin-top: 0;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      /* text-align: justify; */
      /* text-decoration: wavy; */
    }

    @media screen and (max-width: 768px) {
      width: 90%;
      > h1 {
        font-size: 26px;
      }
      > p {
        font-size: 12px;
      }
      margin-left: 0%;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 60%;
    }
  }
`;

export default ZoomInfoHeaderWrapper;
