import styled from 'styled-components';

const ZoomInfoFeaturesWrapper = styled.section`
  display: flex;
  row-gap: 20px;
  column-gap: 10px;
  max-width: 90%;
  margin: 60px 50px;
  flex-wrap: wrap;
  justify-content: center;

  .add-gap {
    margin-bottom: 40px;
  }

  hr {
    width: 3px;
    background-color: #000; /* Add a color of your choice */
    margin: 8 10px;
  }

  @media screen and (max-width: 450px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const OverWrapper = styled.section`
  margin-top: -40px;
  text-align: center;
  > h2 {
    font-size: 40px;
    @media screen and (max-width: 375px) {
      margin-top: 120px;
    }
  }
  > h3 {
    font-size: 24px;
  }
  @media screen and (max-width: 450px) {
    h2 {
      margin-top: 6rem;
    }
  }
  @media screen and (max-width: 376px) {
    h2 {
      margin-top: 20rem;
    }
  }
`;

export default ZoomInfoFeaturesWrapper;
