import styled from 'styled-components';

export const Blogpostwrap = styled.div`
  width: 23%;

  @media screen and (max-width: 450px) {
    width: 80%;
  }

  .thumbnail > img {
    object-fit: contain;
    width: 40%;
    aspect-ratio: 1/1;
    margin-bottom: 20px;
  }

  .content {
    text-align: center;

    .title,
    .excerpt {
      padding: 0;
      margin: 0;
    }

    .title {
      font-size: 22px;
      min-height: 35px;

      @media screen and (max-width: 768px) {
        min-height: 100px;
        font-size: 20px;
      }

      @media screen and (max-width: 450px) {
        min-height: unset;
      }
    }

    .excerpt {
      font-size: 16px;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
`;
export default Blogpostwrap;
