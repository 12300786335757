import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from 'common/components/Button';
// import ZoomInfoHeaderWrapper from './zoominfoHeader.style';
import OkIcon from 'common/assets/image/saasModern/WebApis/ok_icon.svg';
import LeftImg from 'common/assets/image/saasModern/WebApis/Left.png';
import RightImg from 'common/assets/image/saasModern/WebApis/Right.png';
import { Link } from 'gatsby';
import ZoomInfoHeaderWrapper from './zoominfoHeader.style';

const Footer = () => {
  return (
    <ZoomInfoHeaderWrapper>
      <div className="bottom-div">
        <h2>Revolutionize Your CRM Today</h2>
        <div className="main-div">
          <img src={LeftImg} />
          <p>
            Integrate CRM Cleanup API into your CRM system and experience the
            transformative power of data purity. Contact us today to unleash the
            potential of your CRM data and propel your business to new heights
            of success.
          </p>

          <img src={RightImg} />
        </div>

        <Link href="/request-demo">
          <button className="btn">Book A Demo</button>
        </Link>
      </div>
    </ZoomInfoHeaderWrapper>
  );
};

export default Footer;
