import React, { useState, useEffect } from 'react';
import { InlineWidget } from 'react-calendly';
import Button from 'common/components/Button';
import ContainerSectionWrapper, {
  InputGroup,
} from './containerSection.style.js';
import styled from 'styled-components';

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 9999; /* Ensure the pop-up is on top */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px); /* Adjust the blur radius as needed */
`;

const PopupContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border-radius: 20px;
  margin-top: -20px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  width: 90vw;
  height: 90vh;
  overflow: hidden; /* Hide all scrollbars */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px; /* Adjust top position as needed */
  right: 10px; /* Adjust right position as needed */
  background-color: transparent !important;
  border: none;
  color: red !important; /* Change color to black */
  font-size: 40px !important; /* Increase font size */
  font-weight: bold; /* Make font bold */
  cursor: pointer;
  z-index: 9999;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;

  &:hover {
    background-color: #333 !important; /* Change the background color on hover */
    color: black !important; /* Change the text color on hover */
  }

  /* No need to translate based on showInlineWidget prop */
  @media screen and (max-width: 768px) {
    right: 10px; /* Adjust right position for smaller screens if necessary */
  }
`;

const PopupPage = ({ onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [showInlineWidget, setShowInlineWidget] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  useEffect(() => {
    const storedName = localStorage.getItem('name');
    const storedEmail = localStorage.getItem('email');
    setFirstName(storedName || '');
    setEmail(storedEmail || '');
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('utm_source');
    setUtmSource(source || '');
  }, []);

  const valid = email && email.length;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailDomain = email.split('@')[1].toLowerCase();
    try {
      const response = await fetch(
        `https://m.api.azr.clodura.com/freemailpros?msp_eq=${emailDomain}`,
        {
          method: 'GET',
          mode: 'cors',
          credentials: 'same-origin',
        }
      );
      if (response.ok) {
        const res = await response.json();
        if (res.length === 0) {
          const body = {
            data: {
              email: email.toLowerCase(),
              utmSource,
            },
          };
          try {
            const resourceResponse = await fetch(
              'https://m.api.corp.azr.clodura.ai/api/request-book-a-demos/',
              {
                method: 'POST',
                mode: 'cors',
                credentials: 'same-origin',
                headers: {
                  Authorization: `bearer ${process.env.STRAPI_TOKEN}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
              }
            );
            if (resourceResponse.ok) {
              setIsValidEmail(true);
              localStorage.setItem('name', firstName);
              localStorage.setItem('email', email.toLowerCase());
              setShowInlineWidget(true); // Set showInlineWidget to true after form submission
            } else {
              alert('Oops! Something went wrong');
            }
          } catch (error) {
            alert('Something went wrong');
          }
        } else {
          setIsValidEmail(false);
        }
      }
    } catch (error) {
      alert('Something went wrong');
    }
  };

  const handleClosePopup = () => {
    setShowInlineWidget(false);
    onClose(); // Call the onClose function provided by the parent
  };

  return (
    <PopupBackground>
      <PopupContent showInlineWidget={showInlineWidget}>
        <CloseButton
          showInlineWidget={showInlineWidget}
          onClick={handleClosePopup}
        >
          <span>X</span>
        </CloseButton>
        <ContainerSectionWrapper>
          {!showInlineWidget ? (
            <form className="fm" onSubmit={handleSubmit}>
              <InputGroup>
                <label htmlFor="email">
                  Work Email<span>*</span>
                </label>
                <input
                  className={`download-input ${
                    !isValidEmail ? 'invalid-email' : ''
                  }`}
                  type="email"
                  id="email"
                  name="workemail"
                  placeholder="Enter Your Work Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
                {!isValidEmail && (
                  <span className="invalid-email">
                    Please enter your corporate email address only to book
                    <br />a demo!
                  </span>
                )}
              </InputGroup>
              <InputGroup>
                <Button
                  className="book-demo-btn"
                  title="Book A Demo"
                  type="submit"
                  disabled={!valid}
                  style={{ cursor: valid ? 'pointer' : 'not-allowed' }}
                />
              </InputGroup>
            </form>
          ) : (
            <InlineWidget
              url={`https://calendly.com/cloduraai/crm-cleanup-api-demo-discovery-call`}
              prefill={{
                firstName: firstName,
                lastName: lastName,
                email: email,
              }}
            />
          )}
        </ContainerSectionWrapper>
      </PopupContent>
    </PopupBackground>
  );
};

export default PopupPage;
