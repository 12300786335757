import React from 'react';
import BlogPost from '../../../Charity/CRMCleanUpSubHeading';

import CRM from 'common/assets/image/saasModern/CRM/CRM.svg';
import Contact from 'common/assets/image/saasModern/CRM/Contact.svg';
import Enrich from 'common/assets/image/saasModern/CRM/Enrich.svg';

import ZoomInfoFeaturesWrapper, { OverWrapper } from './zoominfoFeatures.style';

const SubHeader = () => {
  return (
    <OverWrapper>
      <h2>Eliminate Duplicate Data and Enrich Your CRM</h2>
      <ZoomInfoFeaturesWrapper>
        <BlogPost
          thumbUrl={CRM}
          title="Eliminate Duplicates"
          excerpt="Our API intelligently identifies and removes duplicate entries, ensuring your data is clean and consistent."
        />
        <hr></hr>
        <BlogPost
          thumbUrl={Contact}
          title="Revamp Old Contacts"
          excerpt="Breathe new life into outdated contact information by updating job positions, email addresses, and company affiliations."
        />
        <hr></hr>
        <BlogPost
          thumbUrl={Enrich}
          title="Enrich your data"
          excerpt="Leverage social profiles as unique identifiers to match current information and enrich your data with valuable insights."
        />
      </ZoomInfoFeaturesWrapper>
    </OverWrapper>
  );
};

export default SubHeader;
