import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import Navbar from 'containers/SaasModern/Navbar';
import Banner from 'containers/Custom/Features/FeatureBannerForAff';
import Footer from 'containers/AgencyModern/Footer';
import Header from '../containers/Custom/CrmCleanupApis/Header';
import Content from '../containers/Custom/CrmCleanupApis/MainComHeading';
import Seo from 'components/seo';
import FaqSection from 'containers/Hosting/FaqForAff';
import Thanksgiving from '../containers/SaasModern/Thanksgiving';
import { Helmet } from 'react-helmet';
import Directory from '../containers/Directory/index';
import { graphql } from 'gatsby';
import G2Slider from '../containers/SaasModern/G2Badges';
import BookDemoButton from '../containers/SaasModern/ScheduleAdemo/ButtonDemo';
import SubHeader from '../containers/Custom/CrmCleanupApis/SubHeader';
import CompareCard from '../containers/Custom/CrmCleanupApis/CompareCard';
import BookDemoCTA from '../containers/Custom/CrmCleanupApis/BookDemoCTA';

const FeaturePage = () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title="Clean & Streamline Your CRM Data with CRM Cleanup API | Clodura.AI"
          description="Clodura.AI's powerful CRM Cleanup API tackles messy data & duplicates, leaving you with accurate & actionable insights. Deduplicate, clean, & update your CRM data automatically for better sales & marketing."
          robots="index, follow"
          canonical="https://www.clodura.ai/crm-cleanup-apis/"
          extraScript={`gtag('event', 'conversion', {'send_to': 'AW-10934282448/LYpPCMWy7IQYENDR790o'});`}
        />
        <Helmet></Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>
          <Header />
          <SubHeader />
          <CompareCard />
          <BookDemoCTA />
          <Content />
          <G2Slider />
          <Footer />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default FeaturePage;
