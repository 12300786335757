import styled from 'styled-components';

const ZoomInfoHeaderWrapper = styled.section`
  display: flex;
  margin: 2px auto;
  align-items: center;
  justify-content: space-evenly;
  /* background: url(${(props) => (props.avatar ? props.avatar : null)}); */
  background-position: center center;
  background-repeat: no-repeat;
  color: black;
  padding: 20px;

  .right-img {
    width: 40%;
    @media screen and (max-width: 420px) {
      width: 100%;
      /* margin-top: 20px; */
    }
    @media screen and (max-width: 440px) {
      width: 100%;
      /* margin-top: 20px; */
    }
  }

  .first-img {
    margin-top: 180px;

    @media screen and (max-width: 768px) {
      width: 100%;
      /* margin-top: 10px; */
    }
    @media screen and (max-width: 440px) {
      width: 100%;
      margin-top: -20px;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 420px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .second-img {
    /* margin-top: 180px; */
    width: 30%;
    @media screen and (max-width: 440px) {
      width: 100%;
      margin-top: 20px;
    }
    @media screen and (max-width: 420px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 16rem;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 100%;
      height: 35px;
    }
  }

  .bottom-div {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 20%;
    text-align: center;
    > h2 {
      font-size: 32px;
      margin-bottom: 0px;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 100%;
      /* height: 35px; */
      padding: 0;
    }

    .book-demo-button {
      background-color: #f58634ff;
      color: #ffffff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      margin-right: 20px;
      cursor: pointer;
      :hover {
        background-color: #2aa275;
        color: black;
        transition: all 0.3s ease 0s;
      }

      @media screen and (max-width: 420px) {
        font-size: 12px;
        width: 70%;
        height: 35px;
      }
    }
  }

  .hr {
    position: relative;
    margin-bottom: -40px;
    z-index: -1;
  }

  .text {
    width: 40%;
    text-align: left;

    h2 {
      font-size: 40px;
      margin-top: 0;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
    }
    h3 {
      font-size: 32px;
    }
    h4 {
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
      > h1 {
        font-size: 26px;
      }
      > p {
        font-size: 12px;
      }
      margin-left: 0%;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 60%;
    }
  }

  .headingOne {
    width: 800px;
    padding: 10px 10px 10px 40px;
    background-color: #03baed;
    color: white;
    border-radius: 20px;
    height: 140px;
    margin-left: -120%;
    z-index: 0;

    @media screen and (max-width: 768px) {
      z-index: 1;
      margin: 0;
      font-size: 12px;
      width: 100%;
      height: 280px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 420px) {
      z-index: 1;
      margin: 0;
      font-size: 12px;
      width: 100%;
      height: 280px;
      margin-bottom: 20px;
      /* height: 35px; */
    }
  }

  .paragraph {
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 10px;
    margin-top: -40px;
    /* z-index: 1; */
  }

  .para {
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 10px;
  }
`;

export default ZoomInfoHeaderWrapper;
