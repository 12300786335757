import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import Button from 'common/components/Button';
import {
  ContentWrapper,
  BannerPart1,
  BannerPart2,
} from './zoominfoHeader.style';
import BookDemoPopup from '../BookADemoForHeader'; // Import your pop-up component

const WebinarHeader = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleBookDemoClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiCrmCleanupApi {
        nodes {
          img1 {
            localFile {
              publicURL
            }
          }
          img2 {
            localFile {
              publicURL
            }
          }
          img3 {
            localFile {
              publicURL
            }
          }
          img4 {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img = ZoominfoPageData.allStrapiCrmCleanupApi.nodes[0];

  return (
    <ContentWrapper id="head">
      <BannerPart1>
        <h1>Introducing CRM Cleanup API:</h1>
        <h2>Your Gateway to Data Purity</h2>
        <p>
          In the dynamic world of customer relationship management (CRM), data
          accuracy is paramount. Outdated, incomplete, or inaccurate information
          can hinder your ability to connect with customers effectively, leading
          to missed opportunities and lost revenue. Introducing CRM Cleanup API,
          the revolutionary tool that empowers you to transform your CRM data
          into a pristine source of truth.
        </p>
        <div className="btn-pr">
          <button className="btn" onClick={handleBookDemoClick}>
            Book A Demo
          </button>
          {showPopup && <BookDemoPopup onClose={handlePopupClose} />}
        </div>
      </BannerPart1>
      <BannerPart2>
        <img className="right-img" src={`${img.img4.localFile.publicURL}`} />
      </BannerPart2>
    </ContentWrapper>
  );
};

export default WebinarHeader;
