import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  margin: 55px auto 0px;
  align-items: center;
  height: 90vh;
  justify-content: space-evenly;
  /* background: url(${(props) => (props.avatar ? props.avatar : null)}); */
  background-position: center center;
  background-repeat: no-repeat;
  padding: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BannerPart1 = styled.div`
  background-color: white;
  text-align: left;
  align-items: center;
  /* order: 1; */
  padding: 10px;
  width: 60%;
  margin-left: 30px;

  > h1 {
    color: white;
    font-size: 38px;
    display: inline-block; /* Make the h3 behave like an inline-block */
    background-color: #03baed; /* Background color applied only to the content area */
    padding: 0 10px; /* Optional: Add padding to the content area */
  }

  > h2 {
    margin-top: -20px;
    font-size: 38px;
  }

  > p {
    margin-top: -10px;
    text-align: center;
    font-size: 18px;
    text-align: left;
    width: 100%;
  }

  > div > p {
    font-size: 14px;
  }

  .btn-pr {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    align-items: center;
    background-color: #f58634ff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    font-weight: 600;
    border-radius: 5px;
    margin-right: 20px;
    cursor: pointer;
    :hover {
      background-color: #86bc4c;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 70%;
      height: 35px;
    }

    @media screen and (max-width: 440px) {
      width: 90%;
      /* padding: 20px; */
    }
  }

  button {
    color: white;
    font: Poppins;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 16rem;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 100%;
      height: 45px;
      padding: 10px 20px;
    }

    @media screen and (max-width: 440px) {
      width: 100%;
      padding: 19px;
      margin-left: 5px;
    }

    @media screen and (max-width: 399px) {
      width: 119%;
      padding: 16px;
      margin-left: 14px;
    }
  }

  .sm-btn {
    display: flex;
    justify-content: left;

    @media screen and (max-width: 768px) {
      gap: 5px;
      > button {
        width: 50%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    margin-bottom: -40px;
    margin-left: -10px;
    > h1 {
      font-size: 22px;
      text-align: center;
      margin-bottom: 25px;
    }
    > h2 {
      font-size: 22px;
      margin-bottom: 10px;
      text-align: center;
    }

    > p {
      text-align: center;
      font-size: 18px;
    }

    width: 100%;
  }

  @media screen and (max-width: 768px) {
    order: 2;
  }
`;

export const BannerPart2 = styled.div`
  width: 50%;
  text-align: left;
  align-items: center;
  .right-img {
    width: 100%;
  }

  @media screen and (max-width: 440px) {
    /* width: 200%;
     */
    .right-img {
      margin-top: 150px;
      width: 200%;
      margin-left: -100px;
    }
  }

  @media screen and (max-width: 431px) {
    /* width: 100%; */
    margin-top: -100px;
  }

  @media screen and (max-width: 420px) {
    width: 100%;
  }

  /* @media screen and (max-width: 414px) {
    .right-img {
      margin-top: 0;
      width: 100%;
      margin-left: 0;
    }
  } */

  @media screen and (max-width: 415px) {
    .right-img {
      margin-top: 100px;
      width: 100%;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 400px) {
    .right-img {
      width: 126%;
      margin-left: -46px;
    }
  }
  @media screen and (max-width: 376px) {
    .right-img {
      margin-top: 390px;
    }
  }
`;
